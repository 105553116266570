import {onMounted, ref} from 'vue';
import {useRoute} from 'vue-router';

export const useCreatingForm = (idString = 'id') => {
  const route = useRoute();

  const isCreating = ref<boolean>(true);

  const isDetail = ref<boolean>(false);

  const id = ref(0);

  const loading = ref(false)

  const formRef = ref<null | HTMLFormElement>(null);

  onMounted(() => {
    if (route && (
        route.name?.toString().includes('chinh-sua')
        || route.name?.toString().includes('cap-nhat')
      )
    ) {
      isCreating.value = false;

      id.value = +route.params[idString];
    }

    if (route && (
        route.name?.toString().includes('chi-tiet')
        || route.name?.toString().includes('detail')
    )
    ) {
      isDetail.value = true;

      isCreating.value = false;

      id.value = +route.params[idString];
    }
  })

  return {
    isCreating,
    isDetail,
    id,
    loading,
    formRef,
  };
}
