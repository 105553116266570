
import {defineComponent} from 'vue';
import PageRowLayout from '@/layout/_shared/PageRowLayout.vue';
import {useRouterCustom} from '@/core/hooks/use-router-custom';
import {useAccount} from '@/core/hooks/use-account';
import {setCurrentPageTitle} from '@/core/helpers/breadcrumb';
import {useCreatingForm} from '@/core/hooks/use-creating-form';
import ButtonCustom from '@/components/shared/buttons/ButtonCustom.vue';

export default defineComponent({
	name: 'chinh-sua-thong-tin-nguoi-dung',

	components: {PageRowLayout, ButtonCustom},

	setup() {
		const { goBack } = useRouterCustom();
		const { currentUserData } = useAccount();
		const { isCreating } = useCreatingForm();

		return {
			goBack,
			user: currentUserData,
			isCreating,
		}
	},

	mounted() {
		setCurrentPageTitle("Tài khoản");
	}
})
